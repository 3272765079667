<template>
    <bid-form-english
        v-if="$store.getters.saleLoaded"
        @update="$emit('update')" />
    
    <v-row v-else>
        <v-col
            cols="12"
            class="text-center">
            <v-progress-circular
                size="48"
                indeterminate />
        </v-col>
    </v-row>
</template>

<script>
import BidFormEnglish from '@/components/BidForm/BidFormEnglish';

export default {
    name: 'PlaceYouBid',

    components: { BidFormEnglish },
};
</script>

<style scoped lang="scss"></style>