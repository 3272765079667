<template>
    <v-row class="mt-5 px-5">
        <v-col class="d-flex justify-end">
            <v-btn
                :color="isMyBidCurrent ? 'grey' : 'primary'"
                @click="$emit('next')">
                {{ isMyBidCurrent ? 'update' : 'submit' }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'BidActions',

    props: {
        isMyBidCurrent: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped></style>