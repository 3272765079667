import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Amplify from 'aws-amplify';
import './assets/style/global.scss';
import { awsConfig } from './aws_config';
import { Utils } from '@/utils';

Vue.config.productionTip = false;

Vue.prototype.$buckets = awsConfig.Storage.AWSS3.buckets;

Vue.filter('currency', function (value) {
    if (!value || typeof value !== 'number') return '';
    return Utils.formatPrice(value);
});

Amplify.configure(awsConfig);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
