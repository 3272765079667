<template>
    <v-card
        class="pa-5 bid-step-card d-flex flex-column">
        <h3 class="mb-8">
            {{ title }}
        </h3>

        <slot />
    </v-card>
</template>

<script>
export default {
    name: 'BidStepCard',

    props: {
        title: {
            type: String,
        },
    },
};
</script>

<style scoped lang="scss">
.bid-step-card {
    height: calc(100vh - 180px);
    overflow-y: auto;
    box-shadow: none !important;
}
</style>