import { API } from '@aws-amplify/api';

export default {
    onSetCurrentBid(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onSetCurrentBid($id: String!) {
            onSetCurrentBid(id: $id) {
                id
                englishAuction {
                    currentBidValue
                    currentBidOwner
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    onEditAuction(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onEditAuction($id: String!) {
            onEditAuction(id: $id) {
                id
                englishAuction {
                    endDate
                    bidIncrement
                    startingPrice
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        }).map(({ value }) => {
            return value.data.onEditAuction.englishAuction;
        });
    },
};
