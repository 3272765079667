import { API } from '@aws-amplify/api';

export const fetchSales = (ids) => {
    API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

    const query = `query getSales($ids: [String!]!){
            getSales(ids: $ids) {
                id
                kind
                status
                englishAuction {
                    startingPrice
                    bidIncrement
                    startDate
                    endDate
                    currentBidValue
                    currentBidOwner
                }
            }
        }`;

    return API.graphql({
        query,
        variables: {
            ids,
        },
    });
};
