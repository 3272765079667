export const getters = {
    agentFullName: (state) => {
        if (state.agent.firstName || state.agent.lastName) {
            return ((state.agent.firstName || '') + ' ' + (state.agent.lastName || '')).trim();
        }

        return null;
    },

    saleLoaded: (state) => {
        return state.sale.id;
    },
};
