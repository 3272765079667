export const awsConfig = {
    Auth: {
        identityPoolId: 'us-east-1:777edbb3-9ab5-40c8-ad2d-2126ffb76f45',
        region: 'us-east-1',
        userPoolId: 'us-east-1_EyVMG2sHl',
        userPoolWebClientId: '2501o80b788kje77887qkkio7o',
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        aws_appsync_graphqlEndpoint: 'https://6kcvjxfvwvgzjimoribej5fani.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        endpoints: [
            {
                name: 'asset',
                endpoint: 'https://6kcvjxfvwvgzjimoribej5fani.appsync-api.us-east-1.amazonaws.com/graphql'
            },
            {
                name: 'auctions',
                endpoint: 'https://sekddjass5hihkmckxaji7tfva.appsync-api.us-east-1.amazonaws.com/graphql'
            }
        ]
    },
    Storage: {
        AWSS3: {
            bucket: 'stage-auen-assets-static',
            region: 'us-east-1',
        }
    }
}
