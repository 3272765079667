import { API } from '@aws-amplify/api';

export const createAuenBid = (bid) => {
    console.log('bid', bid);
    API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;
    const query = `mutation createAuenBid($bid: AuenBidInput!) {
            createAuenBid(bid: $bid) {
                id
                sale {
                    id
                    createdAt
                    assetId
                    agentId
                    status
                    kind
                    englishAuction {
                        startingPrice
                        bidIncrement
                        startDate
                        endDate
                        backups
                        currentBidValue
                        currentBidDate
                        currentBidID
                        currentBidOwner
                        pendingParticipations
                        approvedParticipations
                        details
                    }
                }
                bid {
                    id
                    assetId
                    saleId
                    owner
                    agentId
                    contentAgentId
                    status
                    kind
                    name
                    bidValue
                    createdAt
                    personalInfo {
                        firstName
                        lastName
                        email
                        phoneNumber
                        avatar
                    }
                }
                status
                name
                agent {
                    firstName
                    lastName
                    companyName
                    id
                    phoneNumber
                }
                owner
                personalInfo {
                    firstName
                    lastName
                    email
                    phoneNumber
                    avatar
                }
                saleId
                assetId
                agentId
            }
        }`;

    return API.graphql({
        query,
        variables: {
            bid,
        },
    });
};
