<template>
    <div class="bid d-flex px-2">
        <v-row>
            <v-col cols="12">
                <bid-step-card title="Place You Bid">
                    <place-you-bid />
                </bid-step-card>

                <bid-actions
                    :is-my-bid-current="isMyBidCurrent"
                    @next="onNextClick" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import BidStepCard from '@/components/Bid/BidStepCard';
import PlaceYouBid from '@/components/PlaceYouBid';
import BidActions from '@/components/Bid/BidActions';
import { EVENT_BUS_EVENTS, EventBus } from '@/eventBus';

export default {
    name: 'Home',

    components: {
        BidActions,
        PlaceYouBid,
        BidStepCard,
    },

    data() {
        return {
            drawer: true,
            userId: JSON.parse(localStorage.getItem('currentUser')).sub,
        };
    },

    computed: {
        isMyBidCurrent() {
            return this.$store.state?.sale[this.$store.state.sale.kind]?.currentBidOwner === this.userId;
        },
    },

    methods: {
        onNextClick() {
            EventBus.$emit(EVENT_BUS_EVENTS.NEXT_STEP);
        },
    },
};
</script>

<style scoped lang="scss"></style>