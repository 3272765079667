<template>
    <v-form
        ref="form"
        v-model="valid"
        class="form"
        lazy-validation>
        <v-row>
            <v-col
                class="d-flex flex-column"
                style="position: relative"
                cols="12"
                md="4">
                <span class="mb-1">Current Bid</span>
                <v-btn
                    x-large
                    height="42"
                    :color="isCurrent ? 'success' : 'error'"
                    @click="onCurrentBidClick">
                    {{ startingPrice | currency }}
                </v-btn>
            </v-col>

            <v-col
                class="d-flex flex-column mt-4 mt-md-0"
                cols="12"
                md="4">
                <span class="mb-1">Your Bid</span>
                <app-bid-input
                    v-model="formData.yourBid"
                    :disabled="formData.autoBidding"
                    :min="startingPrice"
                    :disable-errors="isCurrent"
                    :step="auction.bidIncrement" />
            </v-col>

            <v-col
                class="d-flex flex-column"
                cols="12"
                md="4">
                <span class="mb-1">Time Left</span>
                <v-text-field
                    style="font-size: 20px"
                    height="42"
                    dense
                    :value="countdown.value"
                    readonly
                    solo />
            </v-col>
        </v-row>

        <!--<v-row align="start">
            <v-col
                class="mb-5 d-flex align-center"
                cols="12"
                md="4">
                <v-checkbox
                    v-model="formData.autoBidding"
                    label="Autobidding" />

                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            color="primary"
                            class="ml-3 pb-1"
                            size="18"
                            style="cursor: pointer"
                            v-on="on">
                            mdi-help-circle-outline
                        </v-icon>
                    </template>
                    <span class="font-size-12">
                        Our system can automatically bid for you upon <br>
                        reaching the designated Maximum Bid
                    </span>
                </v-tooltip>
            </v-col>

            <v-col
                v-if="formData.autoBidding"
                class="d-flex flex-column"
                cols="12"
                md="4">
                <span class="mb-1">Your Max Bid</span>
                <app-bid-input
                    v-model="formData.yourMaxBid"
                    :min="startingPrice"
                    :step="auction.bidIncrement" />
            </v-col>
        </v-row>-->

        <v-spacer />

        <v-dialog
            v-model="currentBidWarning"
            width="500">
            <v-card>
                <v-card-title class="headline">
                    You are current auction leader
                </v-card-title>

                <v-card-text>
                    Do you want to submit higher bid anyway?
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        text
                        @click="currentBidWarning = false">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="placeBid">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { validators } from '@/utils/validators';
import { EVENT_BUS_EVENTS, EventBus } from '@/eventBus';
import moment from 'moment';
import AppBidInput from '@/components/App/AppBidInput';
import subscriptions from '@/api/subscriptions';

export default {
    name: 'BidFormEnglish',

    components: { AppBidInput },

    data() {
        return {
            valid: false,
            countdown: {
                interval: null,
                value: null,
            },
            formData: {
                yourBid: 0,
                yourMaxBid: 0,
                autoBidding: false,
            },
            userId: JSON.parse(localStorage.getItem('currentUser')).sub,
            startingPrice: 0,
            rules: [(v) => validators.required(v)],
            currentBidWarning: false,
            prevBidValue: null,
        };
    },

    computed: {
        isCurrent() {
            return this.$store.state.sale[this.$store.state.sale.kind].currentBidOwner === this.userId;
        },

        auction() {
            return this.$store.state.sale.englishAuction;
        },
    },

    created() {
        const onSetCurrentBidSub = subscriptions.onSetCurrentBid(this.$store.state.sale.id).subscribe(({ value }) => {
            this.$store.commit('setCurrentBidValue', value.data.onSetCurrentBid.englishAuction.currentBidValue);
            this.$store.commit('setCurrentBidOwner', value.data.onSetCurrentBid.englishAuction.currentBidOwner);
            this.calculateStartingPrice();
        });

        const onEditAuctionSub = subscriptions.onEditAuction(this.$store.state.sale.id).subscribe((englishAuction) => {
            if (!englishAuction) return;
            const { startingPrice, endDate, bidIncrement } = englishAuction;
            const updates = {};
            if (endDate) {
                updates.endDate = endDate;
            }
            if (bidIncrement) {
                updates.bidIncrement = bidIncrement;
            }
            if (startingPrice) {
                updates.startingPrice = startingPrice;
            }
            this.$store.commit('updateEnglishAuction', updates);
            this.calculateStartingPrice();
        });

        this.$once('hook:beforeDestroy', () => {
            onSetCurrentBidSub.unsubscribe();
            onEditAuctionSub.unsubscribe();
        });

        this.calculateStartingPrice();
        this.formData.yourBid = this.formData.yourMaxBid = this.startingPrice + this.auction.bidIncrement;
        this.countdown.value = this.calculateCountdown();

        this.countdown.interval = setInterval(() => {
            this.countdown.value = this.calculateCountdown();
        }, 1000);

        EventBus.$on(EVENT_BUS_EVENTS.NEXT_STEP, this.validate);
        this.$once('hook:beforeDestroy', () => {
            EventBus.$off(EVENT_BUS_EVENTS.NEXT_STEP, this.validate);
        });
    },

    methods: {
        calculateStartingPrice() {
            this.startingPrice = this.auction.currentBidValue ? this.auction.currentBidValue : this.auction.startingPrice;
        },

        async validate() {
            await this.$refs.form.validate();

            if (!this.valid) {
                return;
            }

            if (this.prevBidValue === this.formData.yourBid) {
                this.$store.commit('errorNotification', {
                    text: "It's impossible to place two bids with the same value",
                    closable: true,
                });
                return;
            }

            if (this.isCurrent) {
                this.currentBidWarning = true;
                return;
            }

            this.placeBid();
        },

        placeBid() {
            this.currentBidWarning = false;
            this.$store
                .dispatch('createAuenBid', {
                    saleId: this.$store.state.saleId,
                    bidValue: this.formData.yourBid,
                })
                .then(() => {
                    let subtext = 'Your bid has been successfully submitted';

                    if (this.$store.getters.agentFullName) {
                        subtext += ` to ${this.$store.getters.agentFullName}`;
                    }

                    this.$store.commit('successNotification', {
                        text: 'Thank you!',
                        subtext,
                        closable: true,
                    });

                    this.$emit('update');
                    this.prevBidValue = this.formData.yourBid;
                });
        },

        calculateCountdown() {
            const currentTime = moment().unix();
            const endTime = moment(this.auction.endDate).unix();

            if (endTime - currentTime < 0) {
                return '00:00:00';
            }

            const duration = moment.duration((endTime - currentTime) * 1000, 'milliseconds');

            const days = Math.floor(duration.asMilliseconds() / 1000 / 60 / 60 / 24);
            const hours = duration.hours().toString().padStart(2, '0');
            const minutes = duration.minutes().toString().padStart(2, '0');
            const seconds = duration.seconds().toString().padStart(2, '0');

            return `${days ? days + 'd ' : ''}${hours}:${minutes}:${seconds}`;
        },

        onCurrentBidClick() {
            if (!this.isCurrent) {
                this.formData.yourBid = this.startingPrice + this.auction.bidIncrement;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.row > div {
    padding-top: 0;
    padding-bottom: 0;
}

.form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
</style>