import { Utils } from '@/utils/index';

export const validators = {
    required(v, fieldName = 'Field') {
        return !!v || fieldName + ' is required';
    },

    moreOrEquals(v, min) {
        return v >= min || 'Enter a value greater than or equal to ' + Utils.formatPrice(min);
    },
};
