import Vue from 'vue';
import VueRouter from 'vue-router';
import Bid from '../views/Bid.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/new-bid',
        name: 'Bid',
        component: Bid,
    },
    {
        path: '*',
        redirect: '/new-bid',
    },
];

const router = new VueRouter({
    routes,
});

export default router;
