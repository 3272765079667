<template>
    <v-text-field
        ref="textField"
        class="app-bid-input"
        dense
        :disabled="disabled"
        :value="formattedValue"
        :rules="rules"
        :error-messages="errors"
        solo
        @input="onInput">
        <template slot="append">
            <div class="d-flex flex-column">
                <v-btn
                    :disabled="disabled"
                    height="21"
                    width="21"
                    icon
                    @click="increaseBid">
                    <v-icon>mdi-menu-up</v-icon>
                </v-btn>
                <v-btn
                    :disabled="disabled"
                    height="21"
                    width="21"
                    icon
                    @click="decreaseBid">
                    <v-icon>mdi-menu-down</v-icon>
                </v-btn>
            </div>
        </template>
    </v-text-field>
</template>

<script>
import { Utils } from '@/utils';
import { validators } from '@/utils/validators';

export default {
    name: 'AppBidInput',

    props: {
        value: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableErrors: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            errors: [],
        };
    },

    computed: {
        formattedValue() {
            return Utils.formatPrice(this.value);
        },
        rules() {
            return [
                () => {
                    return validators.moreOrEquals(this.value, this.min + this.step);
                },
            ];
        },
    },

    watch: {
        min() {
            if (this.value < this.min + this.step && !this.disableErrors) {
                this.errors.push(validators.moreOrEquals(this.value, this.min + this.step));
            }
        },

        value() {
            this.errors = [];
        },
    },

    methods: {
        onInput(ev) {
            const parsed = Number.parseInt(ev.replaceAll(/[^0-9.]/g, '')) || 0;
            this.$emit('input', parsed);
        },

        increaseBid() {
            this.$emit('input', this.value + this.step);
        },

        decreaseBid() {
            const decreased = this.value - this.step;

            if (decreased > this.min) {
                this.$emit('input', decreased);
            } else {
                this.$emit('input', this.min + this.step);
            }
        },
    },
};
</script>

<style scoped></style>