<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark>
            <v-app-bar-nav-icon
                v-if="$vuetify.breakpoint.smAndDown"
                @click.stop="drawer = !drawer" />

            <div class="d-flex align-center">
                <h2>
                    {{ title }}
                </h2>
            </div>

            <v-spacer />

            <v-btn
                icon
                @click="onClose">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view v-if="$store.state.userInfo.email" />

            <div
                v-else
                class="d-flex align-center justify-center h-full">
                <v-progress-circular
                    indeterminate
                    size="48" />
            </div>

            <v-overlay v-if="$store.state.loading || auctionHasFinished">
                <v-progress-circular
                    v-if="$store.state.loading"
                    color="primary"
                    indeterminate
                    size="48" />
            </v-overlay>
        </v-main>

        <v-snackbar
            v-model="snackbar.isOpened"
            :timeout="5000"
            bottom
            :color="snackbar.color">
            {{ snackbar.text }}

            <span
                v-if="snackbar.subtext"
                class="d-block text-small text-center">
                {{ snackbar.subtext }}
            </span>

            <v-btn
                v-if="snackbar.closable"
                slot="action"
                icon
                @click="closeSnackbar">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data() {
        return {
            auctionHasFinished: false,
        };
    },

    computed: {
        title() {
            const result = this.$store.state.auction.kind.replace(/([A-Z])/g, ' $1');

            return result.charAt(0).toUpperCase() + result.slice(1);
        },

        snackbar() {
            return this.$store.state.snackbar;
        },
    },

    created() {
        window.addEventListener('message', (ev) => {
            const action = ev?.data?.action;

            switch (action) {
                case 'credentials':
                    this.setCredentials(ev.data.data);
                    break;
                case 'auction-info':
                    this.$store.commit('setAuctionInfo', ev.data.data);
                    this.$store.dispatch('fetchSale');
                    break;
                case 'auction-has-finished':
                    this.auctionHasFinished = true;
                    this.$store.commit('errorNotification', {
                        text:
                            'The auction has already finished. You are no longer able to submit a bid for this asset. ' +
                            'Please contact listing agent for more details.',
                    });
                    setTimeout(() => {
                        this.$store.dispatch('close');
                    }, 5000);
                    break;
            }
        });
    },

    methods: {
        onClose() {
            this.$store.dispatch('close');
        },

        closeSnackbar() {
            this.$store.commit('closeSnackbar');
        },

        setCredentials(credentials) {
            Object.keys(credentials).forEach((key) => {
                localStorage.setItem(key, credentials[key]);
            });

            this.$store.dispatch('fetchUserInfo');
        },
    },
};
</script>
