import { API } from '@aws-amplify/api';

export const fetchUserInfo = () => {
    API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

    const query = `query {
            getUser: getUser {
                email
                personalInfo {
                    firstName
                    lastName
                    phoneNumber
                    avatar
                }
            }
        }`;

    return API.graphql({
        query,
    });
};

export const updatePersonalInfo = (personalInfo) => {
    API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;
    const query = `mutation updatePersonalInfo($personalInfo: PersonalInfoIn) {
            updatePersonalInfo(personalInfo: $personalInfo) {
                firstName
                lastName
                phoneNumber
                avatar
            }
        }`;

    return API.graphql({
        query,
        variables: {
            personalInfo,
        },
    });
};
